import React, { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useBranchData } from '../hooks/use-branch-data';
import { useShowroomUrls } from '../hooks/use-showroom-urls';
import { getSessionStorage, setSessionStorage } from '../components/utilities/functions';

export const BranchContext = createContext({});

export const useBranchContext = () => useContext(BranchContext);

export function BranchProvider(props) {
	const branches = useBranchData();
	const showroomUrls = useShowroomUrls();
	const [currentBranch, setCurrentBranch] = useState({});

	const updateCurrentBranch = (branch) => {
		if (!branch) return;
		if (getSessionStorage('CurrentBranch')?.formal_name === branch.formal_name) return;

		setCurrentBranch(branch);
		setSessionStorage('CurrentBranch', {
			formal_name: branch.formal_name,
			state: branch.state?.abbreviation,
			url: branch.branch_url,
		});
	};

	useEffect(() => {
		const storedBranch = getSessionStorage('CurrentBranch');
		let params = new URLSearchParams(window.location.search);
		let branchID = params.get('branch');
		let state = params.get('state');

		// Check for branch utm to exist within branch data
		if (branchID != null && branches?.find((branch) => branch.branch_retail?.includes(branchID))?.formal_name) {
			const current = branches?.find((branch) => branch.branch_retail?.includes(branchID) && (state ? branch.state?.abbreviation?.toUpperCase() === state?.toUpperCase() : true));

			updateCurrentBranch(current);
			return;
		}

		// Persist current branch through general pages
		if (storedBranch?.formal_name) {
			const savedState = storedBranch.state;
			const savedFormalName = storedBranch.formal_name;
			const current = branches?.find((branch) => branch.formal_name === savedFormalName && (savedState ? branch.state?.abbreviation === savedState : true));
			setCurrentBranch(current);
		}
	}, [branches, showroomUrls]);

	return (
		<BranchContext.Provider
			value={{
				currentBranch,
				setCurrentBranch,
				updateCurrentBranch,
			}}
		>
			{props.children}
		</BranchContext.Provider>
	);
}

BranchProvider.propTypes = {
	children: PropTypes.node,
};
