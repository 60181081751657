import Cookies from 'universal-cookie';
import { setSessionStorage } from '../../../../utilities/functions';
import { setCookie, getCookie } from '../../../../utilities/functions';

const cookies = new Cookies();
let waitForCookie;
// Give 3 seconds for the cookie to be show. If the cookie is not accessible by then it probably isn't coming.
// 500ms each go
const maxInterval = 6;
let currentInterval = 0;
let urlstring = '';

export const generateUTMParams = (queryParams) => {
	const today = new Date();
	const expire = today.setDate(today.getDate() + 548);
	let hasPelCampaignCode = false;
	let pelCampaignCode = null;
	if (Object.prototype.hasOwnProperty.call(queryParams, 'pel_campaign_code')) {
		hasPelCampaignCode = true;
		pelCampaignCode = queryParams['pel_campaign_code'];
		setCookie('pel_campaign_code', queryParams['pel_campaign_code'], new Date(expire), true);
	} else {
		const code = getCookie('pel_campaign_code');
		if (code) {
			hasPelCampaignCode = true;
			pelCampaignCode = code;
		}
	}

	waitForCookie = setInterval(() => intervalFunction(queryParams, hasPelCampaignCode, pelCampaignCode), 500);
	// utmcampaign
	if (Object.prototype.hasOwnProperty.call(queryParams, 'utm_campaign')) {
		if (urlstring !== '') {
			urlstring += '^^';
		}
		urlstring += `utmcampaign:${queryParams['utm_campaign']}`;
	} else if (Object.prototype.hasOwnProperty.call(queryParams, 'utmcampaign')) {
		if (urlstring !== '') {
			urlstring += '^^';
		}
		urlstring += `utmcampaign:${queryParams['utmcampaign']}`;
	} else if (Object.prototype.hasOwnProperty.call(queryParams, 'Campaign')) {
		if (urlstring !== '') {
			urlstring += '^^';
		}
		urlstring += `utmcampaign:${queryParams['Campaign']}`;
	}
	// utmsource
	if (Object.prototype.hasOwnProperty.call(queryParams, 'utm_source')) {
		if (urlstring !== '') {
			urlstring += '^^';
		}
		urlstring += `utmsource:${queryParams['utm_source']}`;
	} else if (Object.prototype.hasOwnProperty.call(queryParams, 'utmsource')) {
		if (urlstring !== '') {
			urlstring += '^^';
		}
		urlstring += `utmsource:${queryParams['utmsource']}`;
	}
	// utmmedium
	if (Object.prototype.hasOwnProperty.call(queryParams, 'utm_medium')) {
		if (urlstring !== '') {
			urlstring += '^^';
		}
		urlstring += `utmmedium:${queryParams['utm_medium']}`;
	} else if (Object.prototype.hasOwnProperty.call(queryParams, 'utmmedium')) {
		if (urlstring !== '') {
			urlstring += '^^';
		}
		urlstring += `utmmedium:${queryParams['utmmedium']}`;
	}
	// utmterm
	if (Object.prototype.hasOwnProperty.call(queryParams, 'utm_term')) {
		if (urlstring !== '') {
			urlstring += '^^';
		}
		urlstring += `utmterm:${queryParams['utm_term']}`;
	} else if (Object.prototype.hasOwnProperty.call(queryParams, 'utmterm')) {
		if (urlstring !== '') {
			urlstring += '^^';
		}
		urlstring += `utmterm:${queryParams['utmterm']}`;
	}
	// utmcontent
	let hasContent = false;
	if (Object.prototype.hasOwnProperty.call(queryParams, 'utm_content')) {
		if (urlstring !== '') {
			urlstring += '^^';
		}
		hasContent = true;
		urlstring += `utmcontent:${queryParams['utm_content']}`;
	} else if (Object.prototype.hasOwnProperty.call(queryParams, 'utmcontent')) {
		if (urlstring !== '') {
			urlstring += '^^';
		}
		hasContent = true;
		urlstring += `utmcontent:${queryParams['utmcontent']}`;
	}
	// gclid
	if (Object.prototype.hasOwnProperty.call(queryParams, 'gclid')) {
		if (urlstring !== '') {
			urlstring += '^^';
		}
		if (!hasContent) {
			urlstring += `utmgclid:${queryParams['gclid']}^^gclid:${queryParams['gclid']}`;
		} else {
			urlstring += `utmcontent:gclid-${queryParams['gclid']}^^utmgclid:${queryParams['gclid']}^^gclid:${queryParams['gclid']}`;
		}
	} else if (Object.prototype.hasOwnProperty.call(queryParams, 'utmgclid')) {
		if (urlstring !== '') {
			urlstring += '^^';
		}
		if (!hasContent) {
			urlstring += `utmgclid:${queryParams['utmgclid']}^^gclid:${queryParams['utmgclid']}`;
		} else {
			urlstring += `utmcontent:gclid-${queryParams['utmgclid']}^^utmgclid:${queryParams['utmgclid']}^^gclid:${queryParams['utmgclid']}`;
		}
	}
	// Network
	if (Object.prototype.hasOwnProperty.call(queryParams, 'Network')) {
		if (urlstring !== '') {
			urlstring += '^^';
		}
		urlstring += `Network:${queryParams['Network']}`;
	}
	// pel_campaign_code
	if (hasPelCampaignCode) {
		if (urlstring !== '') {
			urlstring += '^^';
		}
		urlstring += `pel_campaign_code:${pelCampaignCode}`;
		setSessionStorage('pel_campaign_code', pelCampaignCode, false);
	} else if (/utm(campaign|medium):\(?organic\)?/.test(urlstring)) {
		if (urlstring !== '') {
			urlstring += '^^';
		}
		urlstring += 'pel_campaign_code:PEL198681500';
	}
	// No urlstring thus far
	if (urlstring === '') {
		urlstring = 'utmcampaign:(direct)^^utmsource:(direct)^^utmmedium:(none)^^rtapb:1';
	}
	// Add parentSite
	let documentReferrer = null;
	let referredDomain = null;
	if (document !== undefined) {
		documentReferrer = document.referrer;
		if (documentReferrer != null) {
			const removeProtocol = documentReferrer.substring(documentReferrer.indexOf('//') + 2);
			referredDomain = removeProtocol.substring(0, removeProtocol.indexOf('/'));
		}
	}
	if (referredDomain === '') {
		urlstring += '^^parentSite:www.pella.com';
	} else {
		urlstring += '^^parentSite:' + referredDomain;
	}
	if (cookies.get('ELOQUA')) {
		const eloquaId = cookies.get('ELOQUA');
		urlstring += `^^${eloquaId.replace('GUID=', 'EloquaID:').replace('&FPCVISITED:1', '')}`;
	}

	urlstring += `^^host:${window.location.host}`;

	setSessionStorage('urlstring', urlstring);
};

export const generateBranchUTMParams = (queryParams) => {
	return;
};

const createUTMZObjectFromString = (keyValueString) => {
	let utmzObject = {};
	keyValueString.forEach((keyValuePair) => {
		const temp = keyValuePair.split('=');
		const tempKey = temp[0];
		let tempValue = temp[1];

		if (tempValue != null) {
			tempValue = tempValue.replace('not provided', '');
			tempValue = tempValue.replace('not set', '');
		}

		switch (tempKey) {
			case 'utmccn':
				utmzObject['utmcampaign'] = tempValue;
				break;
			case 'utmcsr':
				utmzObject['utmsource'] = tempValue;
				break;
			case 'utmcmd':
				utmzObject['utmmedium'] = tempValue;
				break;
			case 'utmctr':
				utmzObject['utmterm'] = tempValue;
				break;
			case 'utmcct':
				utmzObject['utmcontent'] = tempValue;
				break;
			case 'utmgclid':
				utmzObject['utmgclid'] = tempValue;
				break;
			default:
				break;
		}
	});
	return utmzObject;
};

const intervalFunction = (queryParams, hasPelCampaignCode, pelCampaignCode) => {
	if (currentInterval < maxInterval) {
		// if __utmz cookie exists use values from it over anything else
		if (cookies.get('__utmz')) {
			urlstring = '';
			const utmzCookie = cookies.get('__utmz');
			const utmzCookiesInfo = utmzCookie.split('.');
			const splitUTMZCookieValue = (str) => {
				const keys = ['utmccn', 'utmcsr', 'utmcmd', 'utmctr', 'utmcct', 'utmgclid'];
				const keyValueArray = [];
				keys.forEach((key) => {
					const index = str.indexOf(key);
					if (index !== -1) {
						let start = index;
						let stop = false;
						let splitCharIndex = -1;
						while (!stop) {
							splitCharIndex = str.indexOf('|', start);
							if (splitCharIndex !== -1) {
								for (let i = 0; i < keys.length; i++) {
									if (str.substring(splitCharIndex + 1, splitCharIndex + 1 + keys[i].length) === keys[i]) {
										stop = true;
										break;
									}
								}
								start = str.indexOf('|', splitCharIndex + 1);
							} else {
								stop = true;
							}
						}
						if (splitCharIndex !== -1 && splitCharIndex > index) {
							keyValueArray.push(str.substring(index, splitCharIndex));
						} else {
							keyValueArray.push(str.substring(index));
						}
					}
				});
				return keyValueArray;
			};
			const utmzCookieValues = createUTMZObjectFromString(splitUTMZCookieValue(utmzCookiesInfo[4]));
			const keys = Object.keys(utmzCookieValues);
			keys.forEach((key) => {
				if (urlstring !== '') {
					urlstring += '^^';
				}
				urlstring += `${key}:${utmzCookieValues[key]}`;
			});

			// Network
			if (Object.prototype.hasOwnProperty.call(queryParams, 'Network')) {
				if (urlstring !== '') {
					urlstring += '^^';
				}
				urlstring += `Network:${queryParams['Network']}`;
			}
			// pel_campaign_code
			if (hasPelCampaignCode) {
				if (urlstring !== '') {
					urlstring += '^^';
				}
				urlstring += `pel_campaign_code:${pelCampaignCode}`;
				setSessionStorage('pel_campaign_code', pelCampaignCode, false);
			} else if (/utm(campaign|medium):\(?organic\)?/.test(urlstring)) {
				if (urlstring !== '') {
					urlstring += '^^';
				}
				urlstring += 'pel_campaign_code:PEL198681500';
			}
			// No urlstring thus far
			if (urlstring === '') {
				urlstring = 'utmcampaign:(direct)^^utmsource:(direct)^^utmmedium:(none)^^rtapb:1';
			}
			// Add parentSite
			let documentReferrer = null;
			let referredDomain = null;
			if (document !== undefined) {
				documentReferrer = document.referrer;
				if (documentReferrer != null) {
					const removeProtocol = documentReferrer.substring(documentReferrer.indexOf('//') + 2);
					referredDomain = removeProtocol.substring(0, removeProtocol.indexOf('/'));
				}
			}
			if (referredDomain === '') {
				urlstring += '^^parentSite:www.pella.com';
			} else {
				urlstring += '^^parentSite:' + referredDomain;
			}
			if (cookies.get('ELOQUA')) {
				const eloquaId = cookies.get('ELOQUA');
				urlstring += `^^${eloquaId.replace('GUID=', 'EloquaID:').replace('&FPCVISITED:1', '')}`;
			}
			setSessionStorage('urlstring', urlstring);
			clearInterval(waitForCookie);
		} else {
			currentInterval++;
		}
	} else {
		clearInterval(waitForCookie);
	}
};
