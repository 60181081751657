import React, { useState, useEffect } from 'react';
import { Script } from 'gatsby';

export const MidAtlanticChat = () => {
	const [showScript, setShowScript] = useState(false);

	useEffect(() => {
		const urlArray = ['/locations/baltimore/', '/locations/washington-dc/', '/locations/eastern-shore/'];
		setShowScript(urlArray.some((url) => window.location.href.includes(url)));
	}, []);

	useEffect(() => {
		if (!showScript) {
			const hatchChatElement = document.getElementsByTagName('hatch-chat')[0];
			const quiqChatElement = document.getElementById('quiq-chat-id');

			if (hatchChatElement) {
				hatchChatElement.remove();
			}

			if (quiqChatElement) {
				quiqChatElement.remove();
			}
		}
	}, [showScript]);

	if (!showScript) return null;

	return (
		<>
			<Script
				id='ma-chat-script'
				key='mid-atlantic-chat'
				strategy='idle'
			>
				{`
                    window.__lc = window.__lc || {};
                    window.__lc.license = 7753391;

                    ;(function(n,t,c){function i(n){return e._h?e._h.apply(null,n):e._q.push(n)};
                    var e={_q:[],_h:null,_v:"2.0",on:function(){i(["on",c.call(arguments)])},once:function(){
                    i(["once",c.call(arguments)])},off:function(){i(["off",c.call(arguments)])},
                    get:function(){if(!e._h)throw new Error("[LiveChatWidget] Youcan't use getters before load.");
                    return
                    i(["get",c.call(arguments)])},call:function(){i(["call",c.call(arguments)])},
                    init:function(){

                    var n=t.createElement("script");
                    n.async=!0,n.type="text/javascript",
                    n.src="https://cdn.livechatinc.com/tracking.js",t.head.appendChild(n)}};
                    !n.__lc.asyncInit&&e.init(),n.LiveChatWidget=n.LiveChatWidget||e}(window,document,[].slice))
                `}
			</Script>

			<noscript
				dangerouslySetInnerHTML={{
					__html: `
                        <a href="https://www.livechatinc.com/chat-with/7753391/" rel="nofollow">Chat with us </a>, powered by <a href="https://www.livechatinc.com/?welcome" rel="noopener nofollow" target="_blank">LiveChat</a>
                    `,
				}}
			/>

			<style
				id='quiq-chat-id'
				dangerouslySetInnerHTML={{ __html: `.quiq-floating-element {display:none !important}` }}
			/>
		</>
	);
};
